const isLocal = (): boolean => {
  return ["localhost", "127.0.0.1"].includes(window.location.hostname);
};

export const REACT_APP_BACKEND_URL = isLocal()
  ? "http://localhost:10020"
  : "https://ai-divorce-backend-14883701676.asia-northeast1.run.app";
export const REACT_APP_FIREBASE_API_KEY = isLocal()
  ? "AIzaSyDnRu5rZFPoWmZUAb5aBdAa78D3xePCaGE"
  : "AIzaSyDxBFHOOlLko9sB5RPztgrPq33NVEhqKes";
export const REACT_APP_FIREBASE_AUTH_DOMAIN = isLocal()
  ? "torute-development.firebaseapp.com"
  : "partner-torute-production.firebaseapp.com";
export const REACT_APP_FIREBASE_PROJECT_ID = isLocal()
  ? "torute-development"
  : "partner-torute-production";
export const REACT_APP_FIREBASE_STORAGE_BUCKET = isLocal()
  ? "torute-development.appspot.com"
  : "partner-torute-production.appspot.com";
export const REACT_APP_FIREBASE_MESSAGING_SENDER_ID = isLocal()
  ? "381181128378"
  : "14883701676";
export const REACT_APP_FIREBASE_MESSAGING_APP_ID = isLocal()
  ? "1:381181128378:web:145ab3410baa1f95305c83"
  : "1:14883701676:web:302ffe05e1858e6150b009";

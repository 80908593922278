import { User } from "firebase/auth";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ChatHistoryResponse, UserResponse } from "../httpResponseType";
import { REACT_APP_BACKEND_URL } from "../constants";

export default function IndividualCase({ currentUser }: { currentUser: User }) {
  const urlParams = useParams<{ id: string }>();
  type CharacteristicWord = { word: string; count: number };
  type Flow = { stage: string; description: string };
  const [chatHistories, setChatHistories] = useState<ChatHistoryResponse[]>();
  const [userInfo, setUserInfo] = useState<UserResponse>();
  const convertCharacteristicWord = (
    gptResponse: ChatHistoryResponse[]
  ): CharacteristicWord[] => {
    const result: CharacteristicWord[] = [];
    gptResponse.forEach((res) => {
      res.gptResponse.keywords.split(",").forEach((obj) => {
        const splitObj = obj.split(":");
        const word = splitObj[0];
        const count = splitObj[1];
        if (word.length === 0 || isNaN(Number(count))) {
          return;
        }
        const exists = result.find((r) => r.word === word);
        if (exists) {
          exists.count += 1;
        } else {
          result.push({ word: word, count: Number(count) });
        }
      });
    });
    return result;
  };
  const [characteristicWord, setCharacteristicWord] =
    useState<CharacteristicWord[]>();
  const convertFlow = (gptResponse: ChatHistoryResponse[]): Flow[] => {
    const result: Flow[] = [];
    gptResponse.forEach((res) => {
      res.gptResponse.flow.split(",").forEach((obj) => {
        const splitObj = obj.split(":");
        const stage = splitObj[0];
        const description = splitObj[1];
        if (stage.length === 0 || description.length === 0) {
          return;
        }
        result.push({ stage: stage, description: description });
      });
    });
    return result;
  };
  const [flow, setFlow] = useState<Flow[]>();

  useEffect(() => {
    currentUser
      .getIdToken(true)
      .then((idToken) => {
        fetch(`${REACT_APP_BACKEND_URL}/restrict/users/${urlParams.id}`, {
          headers: { Authorization: idToken },
        })
          .then(async (response) => await response.json())
          .then((userResponse: UserResponse) => {
            setUserInfo(userResponse);
          });
      })
      .catch((error) => {
        console.log({ error });
      });
  }, []);

  useEffect(() => {
    currentUser
      .getIdToken(true)
      .then((idToken) => {
        fetch(
          `${REACT_APP_BACKEND_URL}/restrict/users/${urlParams.id}/chatHistories`,
          {
            headers: { Authorization: idToken },
          }
        )
          .then(async (response) => await response.json())
          .then((chatHistories: ChatHistoryResponse[]) => {
            if (chatHistories && chatHistories.length > 0) {
              setChatHistories(chatHistories);
              setCharacteristicWord(convertCharacteristicWord(chatHistories));
              setFlow(convertFlow(chatHistories));
            }
          });
      })
      .catch((error) => {
        console.log({ error });
      });
  }, []);
  return (
    <div>
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">
          相談された内容
        </h3>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              氏名
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {userInfo?.userName || "未設定"}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              メールアドレス
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {userInfo?.email || "未設定"}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              連絡手段
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              LINE
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              相談カテゴリー
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              離婚相談
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              相談の特徴語抽出
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr className="divide-x divide-gray-200">
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      特徴語
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      会話内での出現回数
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {characteristicWord &&
                    characteristicWord.map((obj) => (
                      <tr className="divide-x divide-gray-200" key={obj.word}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                          {obj.word}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                          {obj.count}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              話の因数分解
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr className="divide-x divide-gray-200">
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      キーワード
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      内容
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {flow &&
                    flow.map((obj) => (
                      <tr className="divide-x divide-gray-200" key={obj.stage}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                          {obj.stage}
                        </td>
                        <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                          {obj.description}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </dd>
          </div>

          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              相談の音声
            </dt>
            <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              なし
              {/* <ul
                role="list"
                className="divide-y divide-gray-100 rounded-md border border-gray-200"
              >
                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div className="flex w-0 flex-1 items-center">
                    <PaperClipIcon
                      aria-hidden="true"
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                    />
                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                      <span className="truncate font-medium">
                        相談-2024-09-10.mp4
                      </span>
                      <span className="flex-shrink-0 text-gray-400">
                        10分（2.4mb）
                      </span>
                    </div>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a
                      href="#"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      音声を聞く
                    </a>
                  </div>
                </li>
                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div className="flex w-0 flex-1 items-center">
                    <PaperClipIcon
                      aria-hidden="true"
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                    />
                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                      <span className="truncate font-medium">
                        相談-2024-09-11.mp4
                      </span>
                      <span className="flex-shrink-0 text-gray-400">
                        15分（4.4mb）
                      </span>
                    </div>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a
                      href="#"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      音声を聞く
                    </a>
                  </div>
                </li>
              </ul> */}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              相談内容（音声を文字起こしした原文）
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {chatHistories &&
                chatHistories.length > 0 &&
                chatHistories.map((chat) => (
                  <div key={chat.id}>
                    <p>{new Date(chat.createdAt).toLocaleString("ja-JP")}</p>
                    <p>[ユーザー] {chat.requestMessage}</p>
                    <p>[Botの返信] {chat.gptResponse.responseToUser}</p>
                  </div>
                ))}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

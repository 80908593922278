import { useEffect, useState } from "react";
import { Organization, UserResponse } from "../httpResponseType";
import { User } from "firebase/auth";
import { REACT_APP_BACKEND_URL } from "../constants";

export default function Dashboard({
  currentUser,
  currentOrganization,
}: {
  currentUser: User;
  currentOrganization: Organization;
}) {
  const [users, SetUsers] = useState<UserResponse[]>([]);
  // const [openSearchForm, setOpenSearchForm] = useState(false);
  useEffect(() => {
    currentUser
      .getIdToken(true)
      .then((idToken) => {
        fetch(`${REACT_APP_BACKEND_URL}/restrict/users`, {
          headers: { Authorization: idToken },
        })
          .then(async (response) => await response.json())
          .then((users: UserResponse[]) => {
            SetUsers(users);
          });
      })
      .catch((error) => {
        console.log({ error });
      });
  }, []);
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold leading-6 text-gray-900">
            事業者情報
          </h1>
          <div>
            <p>事業所名: {currentOrganization.name}</p>
            <p>事業所コード: {currentOrganization.code}</p>
          </div>
        </div>
      </div>
      <div className="sm:flex sm:items-center mt-10">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold leading-6 text-gray-900">
            相談リスト
          </h1>
        </div>
      </div>
      {/* <button
        type="button"
        className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 mt-8"
        onClick={() => {
          setOpenSearchForm(!openSearchForm);
        }}
      >
        検索フォームを{openSearchForm ? "閉じる" : "開く"}
      </button>
      {openSearchForm && (
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10">
          <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
              <div className="grid">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="clientName"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    相談者名
                  </label>
                  <div className="mt-2">
                    <input
                      id="clientName"
                      name="clientName"
                      type="text"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="text"
                      autoComplete="email"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div>
                    <label
                      htmlFor="contactDateFrom"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      相談日（FROM）
                    </label>
                    <div className="mt-2">
                      <input
                        id="contactDateFrom"
                        name="contactDateFrom"
                        type="datetime-local"
                        autoComplete="address-level1"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="contactDateTo"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      相談日（TO）
                    </label>
                    <div className="mt-2">
                      <input
                        id="contactDateTo"
                        name="contactDateTo"
                        type="datetime-local"
                        autoComplete="postal-code"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <button
                type="submit"
                className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                検索
              </button>
            </div>
          </form>
        </div>
      )} */}

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    相談者名
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    連絡方法・連絡先
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    相談開始日
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">相談内容詳細</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {users &&
                  users.map((user) => (
                    <tr key={user.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {user.userName || "氏名未設定"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        LINE
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {user.createdAt}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <a
                          href={"/users/" + user.id + "/chatHistories"}
                          className="text-blue-600 hover:text-blue-900"
                        >
                          相談内容詳細
                          <span className="sr-only">, {user.id}</span>
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

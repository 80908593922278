import { User } from "firebase/auth";

export default function UserSetting({ currentUser }: { currentUser: User }) {
  return (
    <div className="px-4 flex justify-center">
      <div className="items-center mt-10">
        <div className="flex-auto w-100">
          <h1 className="text-xl font-semibold leading-6 text-gray-900">
            ユーザー初期設定
          </h1>
        </div>

        <div>
          <p className="mt-5 text-lg">①メールアドレス認証</p>
          <p className="">
            メールアドレス宛に届いたURLからメールアドレスの認証を行います。
          </p>

          <p className="mt-5 text-lg">②LINEアカウントを友達追加する</p>
          <p className="">以下のQRコードからAI Chatを友達追加します。</p>
          <div>
            <img
              className="object-contain h-48 w-96"
              src="./torute-ai-chat-qr.png"
              alt="QRコード"
            />
          </div>

          <p className="mt-5 text-lg">③アカウント連携のURLを取得する。</p>
          <p className="">
            友達登録をした後に、Botに対して何かを呼びかけるとアカウント連携のURLが現れます。こちらからアカウント連携をします。
          </p>
          <div>
            <img
              className="object-contain w-96"
              src="./line-1.png"
              alt="QRコード"
            />
          </div>

          <p className="mt-5 text-lg">
            ④LINEアカウントと本サービスのアカウントを連携する
          </p>
          <p className="">
            先ほどWebページより作成した「メールアドレス」「パスワード」をここで入力し、ログインボタンを押します。
          </p>
          <div>
            <img
              className="object-contain w-96"
              src="./line-2.png"
              alt="QRコード"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

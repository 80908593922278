import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import Footer from "./Footer";

export default function NoAuthLayout() {
  return (
    <>
      <div className="flex flex-col min-h-screen">
        <div className="border-b-2">
          <div className="flex lg:flex-1 p-2">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">TORUTE離婚相談（α版）</span>
            </a>
          </div>
        </div>
        <div
          className="flex-column flex-grow"
          style={{ width: "100%", overflow: "scroll" }}
        >
          <div>
            <Suspense fallback={<div>Loading...</div>}>
              <Outlet />
            </Suspense>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

import { useEffect, useState } from "react";
import "./App.css";
import NoAuthLayout from "./components/NoAuthLayout";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignUpOffice from "./Pages/SignUpOffice";
import AuthLayout from "./components/AuthLayout";
import Dashboard from "./Pages/Dashboard";
import IndividualCase from "./Pages/IndividualCase";
import { firebaseAuth } from "./firebaseAuth";
import { getAuth, User } from "firebase/auth";
import SignUpUser from "./Pages/SignUpUser";
import SignInUser from "./Pages/SignInUser";
import SignInOffice from "./Pages/SignInOffice";
import { Organization } from "./httpResponseType";
import LandingPage from "./Pages/LandingPage";
import { REACT_APP_BACKEND_URL } from "./constants";
import UserSetting from "./Pages/UserSetting";

function App() {
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const [currentOrganization, setCurrentOrganization] = useState<
    Organization | undefined
  >(undefined);
  firebaseAuth.onAuthStateChanged(function (user) {
    if (user) {
      setCurrentUser(user);
    }
  });
  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (currentUser && currentOrganization) {
      return;
    }
    if (!user) {
      return;
    }
    if (!currentUser && user) {
      setCurrentUser(user);
    }

    user
      .getIdToken(true)
      .then((idToken) => {
        fetch(`${REACT_APP_BACKEND_URL}/internal/organization`, {
          headers: { Authorization: idToken },
        })
          .then(async (response) => await response.json())
          .then((organization: Organization) => {
            setCurrentOrganization(organization);
          });
      })
      .catch((error) => {
        console.log({ error });
      });
  }, [currentUser, currentOrganization]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route element={<NoAuthLayout />}>
          <Route path="/signup-office" element={<SignUpOffice />} />
          <Route path="/signup-user" element={<SignUpUser />} />
          <Route path="/signin-user" element={<SignInUser />} />
          <Route path="/signin-office" element={<SignInOffice />} />
          <Route
            path="/user-setting"
            element={<UserSetting currentUser={currentUser} />}
          ></Route>
        </Route>
        {currentUser && currentOrganization && (
          <Route
            element={
              <AuthLayout
                currentUser={currentUser}
                currentOrganization={currentOrganization}
              />
            }
          >
            <Route
              path="/dashboard"
              element={
                <Dashboard
                  currentUser={currentUser}
                  currentOrganization={currentOrganization}
                />
              }
            />
            <Route
              path="/users/:id/chatHistories"
              element={<IndividualCase currentUser={currentUser} />}
            />
          </Route>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
